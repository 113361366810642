/* global $ Chart accounting */

$(function () {
  $('#menu').slicknav();

  var CHART_COLORS = [
    { color: '#207568', highlight: '#278f7f' },
    { color: '#3ecccb', highlight: '#45e5e5' },
    { color: '#339e9d', highlight: '#3bb8b6' },
    { color: '#555555', highlight: '#6e6e6e' },
    { color: '#22749c', highlight: '#2886b5' },
    { color: '#a2eceb', highlight: '#a9f5f4' },
    { color: '#7abec2', highlight: '#8ad7db' },
  ];

  $('.pie-chart').each(function () {
    var element = $(this);
    var data = element.data('chart-data');
    var context = this.getContext('2d');
    var dataWithColors = $.map(data, function (item, index) {
      var newItem = $.extend(
        {},
        item,
        CHART_COLORS[index % CHART_COLORS.length]
      );
      newItem.value = accounting.unformat(newItem.value);
      return newItem;
    });
    var chart = new Chart(context).Pie(dataWithColors, {
      segmentShowStroke: false,
      responsive: true,
      tooltipTemplate: '<%= label %>',
      legendTemplate:
        '<div class="legend">' +
        '<table class="legend__table" cellpadding="0" cellspacing="0" border="0">' +
        '<tbody>' +
        '<% for (var i = 0; i < segments.length; i++) { %>' +
        '<tr>' +
        '<td class="legend__value">' +
        '<%= accounting.formatMoney(segments[i].value, "$", 0) %>' +
        '</td>' +
        '<td class="legend__color">' +
        '<span style="background-color:<%= segments[i].fillColor %> "></span>' +
        '</td>' +
        '<td class="legend__label">' +
        '<%= segments[i].label %>' +
        '</td>' +
        '</tr>' +
        '<% } %>' +
        '</tbody>' +
        '</table>' +
        '</div>',
    });
    element.after(chart.generateLegend());
  });
});
